<template>
    <div id="states-list">
       <div class="vx-row">
           <div class="vx-col w-full">
                <vx-card v-if="isMounted">
                   <div id="container"></div>
               </vx-card>
           </div>
       </div>
         <!-- <div class="vx-row">
            <div class="vx-col w-1/3">
                <vs-list>
                    <vs-list-header title="" color="dark"></vs-list-header>
                    <template v-for="(item, index) in statesData">
                        <vs-list-item v-if="index < 11" :key="index" :title="capitalizeFirstLetter(item.state)" >
                            <vs-chip color="danger">{{item.total}}</vs-chip>
                        </vs-list-item>
                    </template>
                </vs-list>
            </div>
            <div class="vx-col w-1/3">
                <vs-list>
                    <vs-list-header title="" color="dark"></vs-list-header>
                    <template v-for="(item, index) in statesData">
                        <vs-list-item v-if="index > 10 && index < 22" :key="index" :title="capitalizeFirstLetter(item.state)" >
                            <vs-chip color="danger">{{item.total}}</vs-chip>
                        </vs-list-item>
                    </template>
                </vs-list>
            </div>
            <div class="vx-col w-1/3">
                <vs-list>
                    <vs-list-header title="" color="dark"></vs-list-header>
                    <template v-for="(item, index) in statesData">
                        <vs-list-item v-if="index > 21" :key="index" :title="capitalizeFirstLetter(item.state)" >
                            <vs-chip color="danger">{{item.total}}</vs-chip>
                        </vs-list-item>
                    </template>
                </vs-list>
            </div>
        </div> -->
    </div>
</template>
<script defer>
import formatHelper from '@components/mixins/formatHelper';
export default {
    name: "statesList",
    mixins: [formatHelper],
    props: {
        regionId: { type: [Number, String], default: 0, required: true }
    },	
    data() {
        return {
            isMounted: false,
            statesData: []
        }
    }, 
    mounted() {
        // this.getStatesData();
    },
    // async beforeCreate() {

    // },
    async created() {
        await this.addLibraries();
        await this.getStatesData();

        (async () => {

            const topology = await fetch(
                'https://code.highcharts.com/mapdata/countries/mx/mx-all.topo.json'
            ).then(response => response.json());

            // Prepare demo data. The data is joined to map using value of 'hc-key'
            // property by default. See API docs for 'joinBy' for more info on linking
            // data and map.
            // this.statesData = [
            //     ['mx-3622', 0], ['mx-bc', 0], ['mx-bs', 0], ['mx-so', 0],
            //     ['mx-cl', 0], ['mx-na', 0], ['mx-cm', 0], ['mx-qr', 0],
            //     ['mx-mx', 0], ['mx-mo', 0], ['mx-df', 0], ['mx-qt', 3],
            //     ['mx-tb', 0], ['mx-cs', 0], ['mx-nl', 0], ['mx-si', 0],
            //     ['mx-ch', 0], ['mx-ve', 0], ['mx-za', 0], ['mx-ag', 0],
            //     ['mx-ja', 0], ['mx-mi', 0], ['mx-oa', 0], ['mx-pu', 0],
            //     ['mx-gr', 0], ['mx-tl', 0], ['mx-tm', 0], ['mx-co', 0],
            //     ['mx-yu', 0], ['mx-dg', 0], ['mx-gj', 0], ['mx-sl', 0],
            //     ['mx-hg', 0]
            // ];

            const data = this.statesData;

            // Create the chart
            Highcharts.mapChart('container', {
                chart: {
                    map: topology
                },
                title: {
                    text: 'República Mexicana'
                },

                subtitle: {
                    text: 'Mapa interactivo'
                },

                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    }
                },

                colorAxis: {
                    min: 1,
                    max: 10,
                    minColor: '#fff2f2',
                    maxColor: '#F04A4A'
                },

                series: [{
                    data: data,
                    name: 'Proyectos instalados',
                    states: {
                        hover: {
                            color: '#626262'
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    }
                }]
            });

        })();

        this.isMounted = true;
    },		
    methods: {
        async addLibraries() {
            let externalScript = document.createElement('script');
            externalScript.setAttribute('src','https://code.highcharts.com/maps/highmaps.js');
            document.head.appendChild(externalScript);
            let externalScript2 = document.createElement('script');
            externalScript2.setAttribute('src','https://code.highcharts.com/maps/modules/exporting.js');
            document.head.appendChild(externalScript2);
        },
        async getStatesData() {
            try {
                const res = await axios.get(`/api/analytics/pgm/demographic/states?region=${this.regionId}&programId=${this.currentProgramData.id}`);
                this.statesData = res.data;
            } catch (error) {
                console.error(error);
                this.errorNotif("Error en la operación.", error);
            }
        },
    }

}
</script>

<style>
#container {
    height: 500px;
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}

.loading {
    margin-top: 10em;
    text-align: center;
    color: gray;
}
</style>