<template>
    <div id="demographic-report">
        <div class="vx-row">
            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                        Reporte <span>demográfico</span>.
                    </h1>
                </vx-card>
            </div>

            <div class="vx-col w-full">
                <vx-card class="mb-6" title="Filtros.">
                    <div class="flex pl-2 mb-4">
                        <vs-select
                            v-if="programIdSelected !== null"
                            label="Zonas"
                            v-model="zoneIdSelected"
                            @change="addFilter('zone_id', $event)"
                            class="mr-2">
                            <vs-select-item text="Todas" value="0"></vs-select-item>
                            <vs-select-item
                                v-for="zone in programSelected.zones"
                                :text="zone.name"
                                :key="zone.id"
                                :value="zone.id"></vs-select-item>
                        </vs-select>

                        <vs-select
                            :disabled="zoneIdSelected == 0 && zoneSelected.regions.length < 1"
                            label="Regiones"
                            v-model="regionIdSelected"
                            @change="addFilter('region_id', $event)">
                            <vs-select-item text="Todas" value="0"></vs-select-item>
                            <vs-select-item
                                v-for="region in zoneSelected.regions"
                                :text="region.name"
                                :key="region.id"
                                :value="region.id"></vs-select-item>
                        </vs-select>
                        <vs-button @click.stop="calculateNewStats()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
                    </div>
                </vx-card>
            </div>

            <div class="vx-col w-full">
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 mb-6">
                        <vx-card style="min-height: 20rem;" title="Género de los solicitantes.">
                            <gender-chart ref="GenderChart" :regionId="regionIdSelected" class="mt-6"></gender-chart>
                        </vx-card>
                    </div>
                    <div style="min-height: 20rem;" class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-6">
                        <vx-card title="Edad de los solicitantes.">
                            <age-chart ref="AgeChart" :regionId="regionIdSelected"></age-chart>
                        </vx-card>
                    </div>

                    <div class="vx-col w-full mb-6">
                        <vx-card title="Ubicación de los proyectos por Estado.">
                            <states-list ref="StatesList" :regionId="regionIdSelected"></states-list>
                        </vx-card>
                    </div>
                </div>
            </div>
            <p class="bold ml-4">Nota: Información en tiempo real.</p>
        </div>
    </div>
</template>

<script>
import GenderChart from "./components/analytics/GenderChart.vue"
import AgeChart from "./components/analytics/AgeChart.vue"
import StatesList from "./components/analytics/StatesList.vue"
export default {
    name: "demographicReport",
    components: {
        GenderChart,
        AgeChart,
        StatesList
    },
    data() {
        return {
            filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
        }
    },
    async mounted() {
        const {data} = await axios.get(`/api/pgm/me/programs`)
        this.programs = data;
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        programIdSelected () {
            return this.currentProgramData.id;
        }
    },
    methods: {
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
        calculateNewStats(){
            this.$refs.GenderChart.getGenderData();
            this.$refs.AgeChart.getAgeData();
            this.$refs.StatesList.getStatesData();
        }
    }
}
</script>

<style>

</style>