<template>
    <div id="age-chart">
        <vue-apex-charts v-if="isMounted" type="bar" height="194" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
    name: "ageChart",
    props: {
        regionId: { type: [Number, String], default: 0, required: true }
    },
    components: {
        VueApexCharts
    },
    data() {
        return {
            isMounted: false,
            series: [
                {
                    name: 'Solicitantes',
                    data: []
                },
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 150,
                    stacked: true,
                    zoom: {
                        enabled: true
                    },
                    toolbar: {
                        tools: {
                            download: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                        },
                        autoSelected: 'none'
                },
                    locales:[{
                        "name": "en",
                        "options": {
                            "toolbar": {
                                "exportToSVG": "Descargar SVG",
                                "exportToPNG": "Descargar PNG",
                                "exportToCSV": "Descargar CSV",
                                "selectionZoom": "Zoom en área",
                                "zoomIn": "Acercar",
                                "zoomOut": "Alejar",
                                "pan": "Paneo",
                                "reset": "Reestablecer"
                            }
                        }
                    }],
                },
                colors: ['#ff4961'], 
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                xaxis: {
                    categories: [],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
        }
    },
    mounted() {
        this.getAgeData();
    },
    methods: {
        async getAgeData() {
            try {
                this.isMounted = false;
                const res = await axios.get(`/api/analytics/pgm/demographic/age?region=${this.regionId}&programId=${this.currentProgramData.id}`);
                this.setChartData(res.data);
                console.log(res.data);
                this.isMounted = true;
            } catch (error) {
                console.error(error);
                this.errorNotif("Error en la operación.", error);
            }
        },
        setChartData(data) {
            try {
                this.chartOptions.xaxis.categories = [];
                this.series[0].data = [];
                for (let index = 0; index < data.projects_installed.length; index++) {
                    const element = data.projects_installed[index].value + data.projects_installed_wf[index].value;
                    this.series[0].data.push(element); 
                    this.chartOptions.xaxis.categories.push(data.projects_installed_wf[index].range); 
                    
                }
            } catch (error) {
                
            }
        }
    }
}
</script>

<style>

</style>