<template>
    <div id="gender-chart">
        <vue-apex-charts type="donut" height="194" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
    name: "genderChart",
    props: {
        regionId: { type: [Number, String], default: 0, required: true }
    },
    components: {
        VueApexCharts
    },
    data() {
        return {
            series: [0,0,0],
            chartOptions: {
                chart: {
                    type: 'donut',
                    height: 400,
                    // stacked: true,
                    zoom: {
                        enabled: true
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                        },
                        autoSelected: 'none'
                    },
                    locales:[{
                        "name": "en",
                        "options": {
                            "toolbar": {
                                "exportToSVG": "Descargar SVG",
                                "exportToPNG": "Descargar PNG",
                                "exportToCSV": "Descargar CSV",
                                "selectionZoom": "Zoom en área",
                                "zoomIn": "Acercar",
                                "zoomOut": "Alejar",
                                "pan": "Paneo",
                                "reset": "Reestablecer"
                            }
                        }
                    }],
                },
                dataLabels: { 
                    enabled: true,
                    formatter: (value) => { return `${value.toFixed(2)} %` }
                },
                donut: { labels: { show: false} },
                tooltip: { y: { formatter: (value) => { return `${value}` } }},
                labels: ["Masculino", "Femenino", "No definido"],
                colors: ['#ff4961', '#3a3939', '#82868b'],
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '50%'
                        }
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        }
    },
    mounted() {
        this.getGenderData();
    },
    methods: {
        async getGenderData() {
            try {
                const res = await axios.get(`/api/analytics/pgm/demographic/gender?region=${this.regionId}&programId=${this.currentProgramData.id}`);
                this.series = [res.data.masculine, res.data.femenine, res.data.not_defined]
            } catch (error) {
                console.error(error);
                this.errorNotif("Error en la operación.", error);
            }
        }
    }
}
</script>

<style>

</style>